export var selectorToolMapper = {
  antibody: {
    description: 'ANTIBODY_SELECTOR_TOOL_DESCRIPTION',
    facets: [{
      key: 'facet_clonality',
      componentType: 'select',
      label: 'ANTIBODY_SELECTOR_TOOL_CLONALITY',
      required: false
    }, {
      key: 'facet_web_titertest_app',
      componentType: 'select',
      label: 'ANTIBODY_SELECTOR_TOOL_TECHNIQUE',
      required: false
    }, {
      key: 'facet_web_species_reactivity',
      componentType: 'select',
      label: 'ANTIBODY_SELECTOR_TOOL_SPECIES_REACTIVITY',
      required: false
    }, {
      key: 'facet_web_conjugate',
      componentType: 'multiselect',
      label: 'ANTIBODY_SELECTOR_TOOL_CONJUGATE',
      required: false
    }],
    searchLabel: 'ANTIBODY_SELECTOR_TOOL_SEARCH_LABEL',
    title: 'ANTIBODY_SELECTOR_TOOL_TITLE'
  },
  millex: {
    description: 'MILLEX_FILTER_SELECTOR_TOOL_DESCRIPTION',
    facets: [{
      key: 'facet_web_sterilization',
      componentType: 'radio',
      label: 'MILLEX_FILTER_SELECTOR_TOOL_WHAT_TYPE',
      required: true
    }, {
      key: 'facet_web_titertest_app',
      componentType: 'multiselect',
      label: 'MILLEX_FILTER_SELECTOR_TOOL_WHAT_APPLICATIONS',
      required: true
    }, {
      key: 'facet_web_pore_size_dim1_um',
      componentType: 'multiselect',
      label: 'MILLEX_FILTER_SELECTOR_TOOL_PORE_SIZE_UNIT',
      required: true
    }, {
      key: 'facet_web_comp_volume_ml',
      componentType: 'multiselect',
      label: 'MILLEX_FILTER_SELECTOR_TOOL_SAMPLE_VOLUME_UNIT',
      required: true
    }],
    searchLabel: '',
    title: 'MILLEX_FILTER_SELECTOR_TOOL_TITLE'
  }
};